.cart-product {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.total {
  display: flex;
  justify-content: flex-end;
  color: #242424;
  font-family: Segoe UI;
  font-size: 15px;
  align-items: center;
  text-overflow: ellipsis;
  width: 100%;
  padding: 1rem;
}

.cart-is-empty {
  padding: 2rem 4rem;
  text-align: center;
}

.total-price {
  color: #242424;
  font-family: Segoe UI;
  font-size: 17px;
  font-weight: bold;
  padding-left: 1rem;
}

.checkout {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: sticky;
  bottom: 0;
  background: white;
  padding-bottom: 1.2rem;
}
.checkout button {
  background: #242424;
  color: #ffff;
  border-radius: 0.5rem;
  padding: 0.6rem 1rem;
  text-align: center;
  font-family: Segoe UI;
  font-size: 17px;
  font-weight: bold;
  width: 95%;
  border: none;
  cursor: pointer;
}
.checkout.variant {
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0;
}
.checkout.variant button {
  width: 49%;
  padding: 0.7rem 1rem;
}
button.btnSecondary {
  background: white;
  color: #242424;
  border: 1px solid #242424;
}
.name {
  color: #707070;
  font-family: Segoe UI;
  font-weight: bold;
  font-size: 17px;
  text-overflow: ellipsis;
  white-space: nowrap;

  overflow: hidden;
}

.desc .variant {
  font-family: Segoe UI;
  font-size: 17px;
  font-weight: bold;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  max-width: 8rem;
}
.price {
  color: #242424;
  font-family: Segoe UI;
  font-size: 17px;
  font-weight: bold;
  text-overflow: ellipsis;
  text-align: right;
}

.remove-icon {
  cursor: pointer;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  width: 5rem;
  padding-right: 0;
}
.remove-icon svg,
.remove-icon img {
}

.cart-mobile-drawer {
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 1);
  padding: 1rem 0.5rem;
  padding-bottom: 0;
  border-radius: 10px 10px 0 0;
  width: 100%;
  margin: 0 auto;

  max-height: 30rem;
  position: absolute;
  transition: bottom ease-in-out 200ms;
  transform: translateZ(0);
  z-index: 10;
  left: 0;
  transform: transale3d(0, 0, 0);
}
.cart-mobile-drawer.variant-drawer {
  padding: 1rem 1.5rem;
}

.cart-mobile-drawer.hide {
  transition: all ease-in-out 200ms;
  z-index: 10;
  bottom: -100%;
  visibility: hidden;
}

.cart-mobile-drawer.show {
  transition: all ease-in-out 200ms;
  z-index: 10;
  bottom: 0;
}

.variant-img {
  width: 5rem;
  border-radius: 1rem;

  max-height: 6rem;
}
.desc {
  display: flex;
  border-bottom: 1px solid #e3e3e3;

  padding-bottom: 1rem;
  margin-left: 0.5rem;
  width: 65%;
  flex-grow: 1;
  justify-content: space-between;
}

@media only screen and (max-width: 1023px) {
  .name {
    max-width: 8rem;
  }
  .name,
  .desc .variant,
  .price,
  .total,
  .total-price {
    font-size: 16px;
  }
  .variant-img {
    width: 5.7rem;
    border-radius: 1rem;
  }
  .desc {
    width: 65%;
  }
  .cart-mobile-drawer {
    width: 100%;
    left: 0;
  }
}
