.product-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 2.5rem;
  padding-top: 1rem;
  flex-wrap: wrap;
}

.name-price {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  padding-right: 2rem;
}

.variant-list select {
  width: 100%;
  /* box-shadow: 1px 2px 10px -2px rgba(0, 0, 0, 0.3); */
  background-color: #fff;
  border-radius: 0.5rem;
  border: 1px solid #514f4f;
  font-weight: bold;
  padding: 1rem;
  margin: 1rem 0;
}
.variant-list {
  padding-top: 1rem;
}
.cancel {
  position: absolute;
  right: 2rem;
  top: 2.3rem;
  cursor: pointer;
}
.secondary {
  color: #707070;
  font-weight: normal;
  font-size: 17px;
}
.primary {
  color: #242424;
  font-size: 18px;
}
