:root {
  --app-height: 100%;
}

* {
  -webkit-tap-highlight-color: transparent; /* transparent with keyword */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* transparent with rgba */
  -webkit-tap-highlight-color: hsla(0, 0, 0, 0); /* transparent with hsla */
  -webkit-tap-highlight-color: #00000000; /* transparent with hex with alpha */
  -webkit-tap-highlight-color: #0000; /* transparent with short hex with alpha */
  user-select: none;
}

@keyframes scaleUp {
  0% {
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
  }
  100% {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
/* 
.is-loading {
  display: none;
  transition: all ease-in-out 500ms;
  transform: translateZ(0);
}
 */
/*Grid for video */
.photo-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  overflow: hidden;
  height: 100%;
  transition: all ease-in-out 500ms;
}

.has-video .content .photo-container {
  display: grid;
  grid-template-columns: 70% 5% 25%;
  width: 100%;
  /*   grid-gap: 3px; */
}
.content.has-title .photo-container {
  display: grid;
  width: 100%;
  grid-template-rows: 100%;
  grid-template-columns: 50px 1fr;
}
.has-header .photo-container header.show {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.has-header .content.has-title .photo-container {
  display: grid;
  width: 100%;
  grid-template-columns: 50px 1fr;
  grid-template-rows: minmax(60px, auto) 1fr;
}
.has-header .photo-container .carousel-nav {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
}
.content.has-title .carousel-nav {
  width: 50px;
}

header {
  position: relative;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex-direction: column;
  transition: all ease-in-out 500ms;
}

header.hide {
  transition: all ease-in-out 500ms;
  opacity: 0;
}

.has-image header.hide {
  max-height: 0;
}
.has-video header.hide {
  max-height: 0;
}
.has-title header.hide {
  max-height: 0;
  width: 0;
  display: none;
}
header.hide .video-react {
  transition: all ease-in-out 500ms;
  padding: 0 !important;
  font-size: inherit;
}

header.hide h2,
header.hide h3 {
  opacity: 0;
}

header h2 {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-style: italic;
  font-style: italic;
  font-weight: lighter;
  font-size: 2.7vh;
  /*color: rgba(32, 38, 49, 1);*/
  top: 0;
}

header h3 {
  mix-blend-mode: hard-light;
  text-align: center;

  font-style: normal;
  font-weight: semi-bold;
  font-size: 2vh;
  /*color: rgba(32, 38, 49, 1);*/
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-style: italic;
}

header h2,
header h3 {
  /* animation: fadeIn ease 400ms; */
  transition: all 400ms;
}

header video {
  outline: none;
  width: 100%;
  height: 100%;
  border-radius: 18px;
}
header video::-webkit-media-controls-time-remaining-display,
header video::-webkit-media-controls-current-time-display {
  display: none;
}
header img {
  outline: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.App {
  min-height: 100vh;
}
#sequence-poll {
  transition: all ease-in-out 250ms;
}
#sequence-poll:not(.has-video) .Photo-Grid:before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: none;
  opacity: 0;
  transition: opacity ease-in-out 100ms;
  position: absolute;
}
#sequence-poll:not(.has-video) .Photo-Grid.is-loading:before {
  background-color: #fff;
  display: flex;
  transition: opacity ease-in-out 100ms;
  z-index: 1000;
  opacity: 1;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  /*   left: 5px;
  top: 5px;
  border-radius: 20px; */
}

#sequence-poll .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all ease-in-out 500ms;
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  /*   .is-embedded.is-maximized .Photo-Grid {
    background-color: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(4px);
    border: 0;
    padding: 2px;
  } */
  .App {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  #main {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-left: 0%;
  }
  #brandContent {
    width: 10%;
  }
  #sequence-poll {
    height: 100%;
    margin: 0.2% 2%;
    width: 100%;
    padding: 1vh;
    border-radius: 20px;
  }
  #sequence-poll.is-maximized {
    margin: 0.2% 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.15);
    /*backdrop-filter: blur(4px);*/
    border: 0;
    padding: 2px;
  }
  .content {
    position: relative;
    transition: all ease-in-out 500ms;
    height: 90%; /* Dekstop */
  }

  .is-embedded.is-maximized .content {
    height: 100%;
  }

  .has-video header.show {
    border-radius: 18px;
  }

  .has-video header {
    width: 100%;
    transition: all ease-in-out 500ms;
    background-color: #000;
    align-self: center;
    align-items: center;
    height: 100%;
    flex: 1;
  }
  .has-image header:not(.hide) {
    max-height: 30vh;
  }
}
.contester-logo-wrapper {
  position: relative;
}

#sequence-poll:not(.has-footer-logo) .contester-logo-wrapper {
  display: none;
}

@-webkit-keyframes shineShort {
  to {
    top: 150%;
    left: 150%;
  }
}

@-webkit-keyframes shineLong {
  100% {
    top: 150%;
    left: 150%;
  }
  33% {
    top: 150%;
    left: 150%;
  }
  0% {
    top: -120%;
    left: -120%;
  }
}

.is-highlighted {
  position: relative;
  overflow: hidden;
}

.is-highlighted:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    transparent 0%,
    rgba(255, 255, 255, 0) 35%,
    rgba(255, 255, 255, 0.5) 46%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0.5) 54%,
    rgba(255, 255, 255, 0) 65%,
    transparent 100%
  );

  content: '';
  height: 200%;
  left: -120%;
  position: absolute;
  top: -120%;
  width: 200%;
  z-index: 10;
  pointer-events: none;
}

.back-button-animation:after {
  animation: shineShort 2s cubic-bezier(0.12, 0.89, 0.98, 0.47) 0s infinite,
    shineLong 7s cubic-bezier(0.12, 0.89, 0.98, 0.47) 20s infinite;
}
/* Tablet */
@media only screen and (min-width: 1280px) {
  #sequence-poll {
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  #sequence-poll:not(.is-embedded) {
    height: 91%;
  }
}

/* Mobile */
@media only screen and (max-width: 1023px) {
  /* header video {
    border-radius: 6px;
  } */
  #sequence-poll:not(.has-video) .Photo-Grid.is-loading:before {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  #main {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-left: 0%;
  }
  .content {
    /* height:90vh; */
    height: 93vh;
    transition: all ease-in-out 500ms;
    padding: 5px;
  }
  .has-video .is-maximized .content,
  #sequence-poll:not(.has-header) .content.has-title {
    height: 100%;
    transition: all ease-in-out 500ms;
  }
  .has-video .is-maximized .content,
  #sequence-poll:not(.has-header) .content.has-title {
    height: 100%;
  }
  .is-maximized .content {
    height: 100%;
    transition: all ease-in-out 500ms;
  }

  #brandInfo {
    display: none;
  }
  .is-embedded .carousel-nav {
    display: none;
  }
  .has-video .content .photo-container {
    grid-template-columns: 70% 25%;
    transition: all ease-in-out 500ms;
  }
  .is-maximized .content .photo-container {
    padding-bottom: 0;
    background-color: rgba(0, 0, 0, 0.15);
    /*backdrop-filter: blur(4px);*/
    border: 0;
    padding: 2px;
    border-radius: 6px;
  }

  .has-video .content .photo-container header.show {
    margin-bottom: 2px;
  }

  .has-image header:not(.hide) {
    max-height: 30vh;
  }

  #sequence-poll {
    height: 100vh;
    max-width: 100vw;
    width: 100%;
  }
  #sequence-poll.is-maximized {
    width: 100%;
    min-width: 250px;
  }

  .App {
    justify-content: center;
  }
  .is-embedded.is-maximized header video {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) header video {
    border-radius: 18px;
  }
}
@media only screen and (max-width: 1023px) and (orientation: portrait) {
  #sequence-poll.is-maximized {
    min-height: 350px;
  }
}
/*Title */
.has-header {
}
.has-header .content.has-title .photo-container header {
  padding: 10px;
}
.has-header .content.has-title .photo-container .title,
.has-header .content.has-title .photo-container .subtitle {
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
}
.has-header .content.has-title .photo-container .title {
  font-size: 36px;
}

.is-embedded:not(.is-maximized).has-header
  .content.has-title
  .photo-container
  .title {
  font-size: 16px;
}
.has-header .content.has-title .photo-container .subtitle {
  font-size: 28px;
}
@media only screen and (max-width: 1023px) {
  .has-video .content .photo-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 40% 60%;
    width: 100%;
  }

  .content.has-title .photo-container {
    grid-template-columns: 1fr;
  }
  .has-header .photo-container header.show {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .has-header .content.has-title .photo-container {
    grid-template-rows: minmax(60px, auto) 1fr;
    grid-template-columns: 1fr;
    transition: all ease-in-out 500ms;
  }

  .has-header .content.has-title .photo-container .title {
    font-size: 21px;
  }
  .has-header .content.has-title .photo-container .subtitle {
    font-size: 16px;
  }
}
@media only screen (min-width: 769px) and (max-width: 900px) {
  .has-video .content .photo-container {
    padding-bottom: 10px;
    grid-template-columns: 70% 25%;
    grid-template-rows: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .has-header .photo-container header.show {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }
  .content.has-title .photo-container {
    grid-template-columns: 1fr;
  }
  .has-header .content.has-title .photo-container {
    grid-template-columns: 1fr;
    transition: all ease-in-out 500ms;
  }
}
@media only screen and (max-width: 600px) {
  .is-embedded:not(.is-maximized).has-video .content .Photo-Grid {
    display: none;
  }
  .is-embedded:not(.is-maximized).has-video .content .photo-container {
    grid-template-columns: 100%;
    grid-template-rows: 100%;
  }
}

@media only screen and (max-width: 1023px) and (orientation: portrait) {
  #sequence-poll:not(.is-maximized) .content .photo-container {
    position: relative;
    overflow: hidden;
    padding: 0;
    transition: all ease-in-out 500ms;
  }
  .is-maximized .content .photo-container {
    padding: 2px;
  }
}

@media only screen and (max-width: 1023px) and (orientation: landscape) {
  .content {
    height: 100%;
  }
  .has-header .TopBar {
    display: none;
  }
  .content .photo-container {
    padding: 0;
  }
  .has-video .content .photo-container {
    padding-bottom: 0;
  }
  .is-maximized .content .photo-container {
    padding-bottom: 0;
    background-color: rgba(0, 0, 0, 0.15);
    /*backdrop-filter: blur(4px);*/
    border: 0;
    padding: 2px;
    border-radius: 6px;
  }
  .has-video .content .photo-container {
    grid-template-columns: 70% 30%;
    grid-template-rows: 100%;
  }
  .has-video .content .photo-container header.show {
    padding-right: 3px;
    margin-bottom: 0;
  }
  .has-header .content.has-title .photo-container .subtitle {
    font-size: 28px;
  }
}
