@font-face {
  font-family: Aino;
  src: url('../../styles/fonts/Aino/Aino_31_170411-Regular.woff2');
}

@font-face {
  font-family: Aino;
  font-weight: bold;
  src: url('../../styles/fonts/Aino/Aino_31_170411-Bold.woff2');
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#overlay-buttons:hover {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

#overlay-buttons {
  position: absolute;
  width: 100%;
  overflow: hidden;
  z-index: 10;
  opacity: 0;
}
#overlay-buttons.show {
  animation: fadeIn ease 400ms;
  animation-delay: 700ms;
  animation-fill-mode: forwards;
}
#overlay-buttons.hide {
  opacity: 0;
}

.product-tag {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background ease-in-out 0.25s;
}

.product-tag.isHovered .product-circle:before {
  border-bottom-color: rgba(255, 255, 255, 100%);
  transition: border ease-in-out 0.25s;
}

.product-tag.isHovered .product-info {
  background-color: rgba(255, 255, 255, 100%);
  transition: background ease-in-out 0.25s;
}

.product-circle {
  height: 1.5vh;
  width: 1.5vh;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  margin-bottom: 2vh;
  position: absolute;
  bottom: 100%;
  left: -0.75vh;
}

.product-circle:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: -0.2vh;
  border: 1vh solid transparent;
  border-top: none;
  border-bottom-color: rgba(255, 255, 255, 0.9);
  top: 2.5vh;
  transition: border ease-in-out 0.25s;
}

.product-info {
  font-family: Aino;
  padding: 0.5rem 1.2rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px 10px 10px 10px;
  color: black;
  text-align: right;
  transition: transform linear 200ms;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  left: -50%;
  transition: background ease-in-out 0.25s;
}

.product-info:hover {
  cursor: pointer;
}

.product-tag.hide {
  opacity: 0;
  transition: all linear 200ms;
  transform: scale(0);
  transform-origin: 0 -2.75vh;
}

.product-tag.show {
  opacity: 1;
  transform: scale(1);
  transition: all linear 200ms;
  transform-origin: 0 -2.75vh;
}

.msg-card {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1rem;
  border-radius: 10px;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  width: 94%;
  box-shadow: 0 1px 3px #00000021;

  margin-bottom: 0.5%;
  transform: translateZ(0);
}

#toggleDescription {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 1rem;
  border-radius: 10px;
  width: 94%;
  height: 7%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin: auto;
  bottom: 1%;
  top: 92%;
  box-shadow: 0 1px 3px #00000021;
}

#toggleDescription:hover {
  /* background-color: rgba(170, 170, 170, 0.7); */
  background-color: rgba(255, 255, 255, 100%);
}

.msg-card:hover {
  background-color: rgba(255, 255, 255, 100%);
}

#description {
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
  border-radius: 10px 10px 0 0;
  left: 2.5%;
  width: 95%;
  height: 80%;
  position: absolute;
  transition: top ease-in-out 200ms;
  transform: translateZ(0);
}
.sticky-heading {
  position: sticky;
  top: 0;
  min-height: auto;
  height: auto;
}

.sticky-heading h1 {
  background-color: rgba(255, 255, 255, 0.9);
}

#description img {
  width: 100%;
  border-radius: 10px;
  filter: grayscale(100%);
  height: 100%;
  object-fit: cover;
}

#description h1 {
  font-size: 20px;
  font-weight: bold;
  color: #154061;
  margin-bottom: 1.5rem;
  margin-top: 1.2rem;
}

#description p {
  font-family: Aino;
  font-size: 16px;
  line-height: 30px;
  color: #020202;
  margin-bottom: 1.5rem;
}

a.cta {
  font-family: Aino;
  font-weight: bold;
  text-decoration: none;
  color: #020202;
  font-size: 18px;
}

a.cta:visited {
  text-decoration: none;
  color: #020202;
}
a.cta:hover {
  text-decoration: none;
  color: #020202;
}
a.cta:focus {
  text-decoration: none;
  color: #020202;
}
a.cta:hover,
a.cta:active {
  text-decoration: none;
  color: #020202;
}

.draggableBox {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: 1%;
  width: 100%;
  z-index: -1;
}

.bottom-overlay {
  position: absolute;
  width: 100%;
  bottom: 1%;
}

#card-list {
  width: 100%;
  position: relative;
  bottom: 8%;
  left: 3%;
}
.msg-card,
#toggleDescription {
  z-index: 3;
}

.msg-card.input-form {
  background-color: #f0f0f0f0;
  padding: 15px 15px;
}
.input-form .title {
  font-family: 'Aino';
  font-size: 18px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 17px;
  display: flex;
  text-align: center;
  justify-content: center;
  border-radius: 10px;
  margin-bottom: 10px;
}
.input-form .input-container {
  display: flex;
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  transition: all 0.2s;
}
.input-container button {
  border: none;
  background: #232323;
  color: #ffffff;
  border-radius: 35px;
  padding: 17px 25px;
  font-family: 'Aino';
  font-size: 18px;
  min-width: 40%;
  cursor: pointer;
}
.input-container button:hover {
  background: #232323cc;
}
.input-form .show {
  visibility: visible;
  opacity: 1;
}
.input-form .hide {
  visibility: hidden;
  transition: all 0.2s;
  opacity: 0;
}
.input-container p {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  color: #0000d1;
  font-family: 'Aino';
}
.input-container input:focus {
  border-color: #232323;
}
.input-container input {
  margin-bottom: 10px;
  border: 1px solid #e1e1e1;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  width: 100%;
  padding: 17px;
  color: #cccccc;
  font-family: 'Aino';
  display: flex;
  text-align: center;
  justify-content: center;
  outline: 0;
}

.msg-card.hide,
#toggleDescription.hide {
  opacity: 0;
  visibility: hidden;
  transform: translateY(100%);
  transition: all 200ms;
}
#toggleDescription p {
  color: #020202;
  font-weight: 600;
  font-size: 17px;
}

.msg-card p {
  font-family: Aino;
  color: #154061;
}
.msg-card p.sub-heading {
  font-style: normal;
  text-align: center;
}
.msg-card p.heading {
  font-weight: bold;
  font-style: normal;
  text-align: center;
}
.addtocart p {
  color: #020202;
}
.addtocart {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.msg-card.show,
#toggleDescription.show {
  opacity: 1;
  transition: opacity 200ms, transform 200ms, background 250ms;
}

.drawer-link {
  display: flex;
  justify-content: center;
}

#description.hide {
  transition: top ease-in-out 300ms;
  z-index: 10;
  top: 100%;
}

#description.show {
  transition: top ease-in-out 300ms;
  z-index: 10;
  top: 20%;
}

#gray-overlay {
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9;
  position: absolute;
  border-radius: 10px;
  transition: opacity linear 200ms;
}

#gray-overlay.show {
  transition: opacity linear 200ms;
  height: 100%;
  top: 0;
  width: 100%;
}

#gray-overlay.hide {
  transition: opacity linear 300ms;
  opacity: 0;
  height: 0%;
  top: 100%;
}
/* Desktop */
@media only screen and (min-width: 1024px) {
  #overlay-buttons {
    height: 100%;
  }
}

/* Mobile */
@media only screen and (max-width: 1023px) {
  #overlay-buttons {
    height: 100%;
  }
  .bottom-overlay {
    bottom: 3.2rem;
  }
}
