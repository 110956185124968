#sp-footer {
  width: 100%;
  position: relative;
  padding: 1.5vh 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.footer-logo {
  width: 100px;
  position: absolute;
  left: 10px;
  bottom: 20px;
  height: 35px;
}
.has-footer-logo:not(.has-video) .footer-logo {
  left: 65px;
  bottom: 15px;
}
.footer-logo-mobile {
  position: absolute;
  bottom: 10px;
  width: 60px;
  left: 10px;
  height: 30px;
}
.is-maximized:not(.has-video) .footer-logo {
  left: 65px;
  bottom: 5px;
  height: 35px;
}

.footer-logo.white path {
  fill: #fff;
}

.fade {
  animation: fade-in-keyframes 1s;
}

@keyframes fade-in-keyframes {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0.3rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#sp-footer .text {
  font-size: 2vh;
  font-family: Aino;
  font-style: normal;
  color: #b6b6b6;
  padding-left: 1%;
}

#options-desktop {
  padding: 0;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.5vh;
}
#options-desktop:hover {
  background-color: #f5f5f5;
}
#options-desktop img {
  width: 60%;
  height: 90%;
}

#options-menu {
  width: 10rem;
  position: absolute;
  right: -12rem;
}
#options-menu img {
  height: 100%;
  width: 10%;
  margin-right: 1vh;
}
#options-menu .menu-item {
  border-radius: 1.5vh;
  border: 0.5vh solid white;
  cursor: pointer;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-family: 'Segoe UI';
  align-items: center;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.07);
  padding: 1vh;
}

#options-menu .menu-item:hover {
  background-color: #f5f5f5;
}

#back-btn-mobile {
  display: none;
  background-color: rgba(255, 255, 255, 0.801);
  border-radius: 10px;
  border: none;
  width: 4.5rem;
  align-items: center;
  justify-content: center;
  height: 100%;
}
#back-btn-mobile svg {
  color: #6e6e6e;
}
.is-maximized .options-mobile {
  position: relative;
}

.options-mobile .menu-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  left: calc(87% - 2rem);
  padding: 0 1rem;
  cursor: pointer;
  border-radius: 10px;
  border-bottom: 4px solid lighten(gray, 70%);
}

.is-maximized .menu-toggle {
  display: none;
}
.has-video:not(.is-maximized) .options-mobile .menu-toggle {
  left: calc(86% - 2rem);
}
.is-maximized.has-video .options-mobile .menu-toggle {
  left: calc(87% - 2rem);
}

.options-mobile .options,
.footer-btn {
  box-shadow: 0 1px 6px #00000021;
  border-radius: 10px;

  background-color: hsla(0, 0%, 100%, 0.801);
}

.footer-btn:hover {
  background-color: #fcfcfced;
}

#sp-footer .menu-toggle {
  height: 2.2rem;
  bottom: 1vh;
  width: 4.5rem;
  position: absolute;
}

.is-embedded #sp-footer .footer-btn {
  bottom: 2vh;
}
.options-mobile .options {
  position: fixed;
  bottom: 5.5rem;
  left: 79%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 2rem;
  height: 0;
  width: 12rem;
}

#sequence-poll:not(.has-video) .options-mobile .options {
  left: 44%;
}

#sequence-poll:not(.has-video) #sp-footer {
  width: calc(100% - 50px);
  margin-left: 50px;
}

.is-embedded .options-mobile .options {
  bottom: 3.5rem;
}
.options .option {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}
.is-maximized.has-video .footer-buttons {
  bottom: 2vh;
  position: absolute;
  align-items: flex-end;
}
.is-maximized.has-video #sp-footer {
  position: absolute;
  bottom: 0;
}
button.cart.footer-btn {
  min-width: 4.5rem;
  cursor: pointer;
  border: none;
  padding: 0.5rem;
  animation: slideUp ease-in-out 250ms;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 4%;
}
.cart-count {
  color: white;
  padding: 0 0.5rem;
  background: #514f4f;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.right-button {
  width: 5rem;
}
.is-embedded:not(.is-maximized) .contester-logo-wrapper {
  display: none;
}

.menu-toggle:hover {
  background-color: hsla(0, 0%, 95%, 0.801);
  box-shadow: 0px 15px 25px -5px rgba(darken(hsla(0, 0%, 95%, 0.801), 40%));
}

.menu-toggle:active {
  background-color: white;
  box-shadow: 0px 4px 8px rgba(darken(hsla(0, 0%, 95%, 0.801), 30%));
}

.option:hover {
  background-color: hsla(0, 0%, 90%, 0.801);
  box-shadow: 0px 15px 25px -5px rgba(darken(hsla(0, 0%, 95%, 0.801), 40%));
}
.desktop-logo {
  width: 100px;
}

/* Widget desktop*/
.is-embedded:not(.is-maximized) .footer-logo {
  display: none;
}
.is-embedded:not(.is-maximized) .menu-toggle.footer-btn {
  display: none;
}

.is-embedded:not(.is-maximized).has-header .photo-container header.show {
  border-radius: 18px;
  margin-bottom: 10px;
  background: linear-gradient(#0000009e, 4%, transparent);
  background-color: #fff;
}
.is-maximized.has-header .photo-container header.show {
  margin-bottom: 0;
  border-radius: 6px 0 0;
  background-color: #fff;
}

.is-maximized #sp-footer {
  display: flex;
  position: absolute;
  bottom: 0;
}
.is-embedded:not(.is-maximized) #sp-footer {
  display: none;
}
#sp-footer {
  width: 0;
  bottom: 0;
  left: 0;
  padding: 0 0.5vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}
.content.has-title #sp-footer .footer-logo,
.content.has-title #sp-footer .footer-logo-mobile {
  left: 65px;
}
.has-video #sp-footer .footer-btn.menu-toggle {
  left: calc(88% - 5px);
}

.is-maximized.has-video #sp-footer .footer-btn {
  position: fixed;
  left: 90%;
}

.is-maximized .footer-buttons {
  width: auto;
}

@media only screen and (min-width: 1024px) {
  #sequence-poll:not(.is-embedded):not(.has-video) .options-mobile .options {
    left: 45%;
  }
  #sequence-poll:not(.has-video) #sp-footer .footer-btn {
    left: 50%;
    transform: translate(-50%, 0);
  }
  #sp-footer .footer-btn {
    left: 50%;
    transform: translate(-50%, 0);
  }
}
/* Mobile */
@media only screen and (max-width: 1023px) {
  .is-maximized.has-header .photo-container header.show {
    border-radius: 8px 8px 0 0;
  }
  .footer-buttons {
    flex-direction: row;
    position: absolute;
  }
  .is-maximized.has-video #sp-footer #back-btn-mobile {
    position: fixed;
    left: 90%;
  }
  .cart {
    position: absolute;
  }
  #sp-footer.hide {
    display: none;
  }
  .options-mobile .options {
    position: fixed;
    padding: 1.2rem 1rem;
    bottom: 4.2rem;
    left: calc(50% - 5.5rem);
    width: 11rem;
  }

  #sp-footer .footer-btn {
    margin-left: 0;
    width: 4rem;
    bottom: 2vh;
    position: absolute;
  }
  #sp-footer .right-button {
    position: fixed;
    right: 0;
  }

  .options-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .options-mobile .menu-toggle {
    width: 4rem;
  }
  .is-maximized .options-mobile .menu-toggle {
    left: calc(50% - 2rem);
  }
  .is-maximized.has-video .options-mobile .menu-toggle {
    left: calc(50% - 2rem);
  }

  #back-btn-mobile {
    display: flex;
    position: fixed;
    height: 2.2rem;
    bottom: 1vh;
    width: 4.5rem;
    position: absolute;
  }

  #sp-footer .text {
    font-size: 1.85vh;
  }
  #sp-footer #options-desktop {
    display: none;
  }
  .is-maximized #sp-footer {
    display: flex;
  }

  .is-maximized:not(.has-video) .footer-logo {
    left: 15px;
    bottom: 10px;
  }
  .is-maximized:not(.has-video) .options-mobile .menu-toggle {
    left: calc(50% - 2rem);
  }

  .content.has-title #sp-footer .footer-logo,
  .content.has-title #sp-footer .footer-logo-mobile {
    left: 15px;
  }
  #back-btn-mobile {
    position: fixed;
    right: 0;
  }

  #sequence-poll:not(.has-video) #sp-footer {
    width: 100%;
    margin-left: 0px;
  }

  #sequence-poll:not(.has-video) #sp-footer .menu-toggle {
    left: 50%;
    transform: translate(-50%, 0);
  }

  #sp-footer .footer-btn {
    left: 50%;
    transform: translate(-50%, 0);
  }
}

@media only screen and (max-width: 1023px) and (orientation: landscape) {
  .has-video:not(.is-embedded) .footer-logo-mobile {
    bottom: 30px;
  }
  .is-maximized.has-video .options-mobile .options {
    left: 70%;
    bottom: 3.5rem;
    position: fixed;
  }
  #sp-footer .footer-btn {
    bottom: 2vh;
  }

  .is-maximized.has-video #sp-footer #back-btn-mobile,
  .is-maximized #sp-footer #back-btn-mobile {
    position: fixed;
    left: 96%;
  }
  #sequence-poll:not(.has-video) .options-mobile .options {
    bottom: 3.2rem;
  }
  #sequence-poll.has-video .options-mobile .options {
    left: calc(79% - 9%);
  }
  .is-maximized.has-video .options-mobile .menu-toggle {
    width: 3rem;
    left: 70%;
  }
  #sequence-poll:not(.has-video) .options-mobile .menu-toggle {
    left: calc(54% - 4rem);
  }
  #back-btn-mobile {
    right: 1%;
    width: 3.5rem;
  }

  .is-maximized.has-video .footer-logo-mobile {
    left: 15px;
    height: auto;
    bottom: 40px;
  }

  .is-maximized.has-video .footer-logo-mobile {
    left: 15px;
    height: auto;
    bottom: 40px;
  }

  .has-video:not(.is-maximized) .options-mobile .menu-toggle {
    left: 70%;
  }

  #sequence-poll:not(.has-video).options-mobile .options {
    left: 39%;
  }
  #sequence-poll:not(.is-maximized).has-video
    #sp-footer
    .footer-btn.menu-toggle {
    bottom: 2vh;
    left: 74%;
  }
  .has-video #back-btn-mobile {
    bottom: 2vh;
  }
}

@media only screen and (max-width: 1023px) and (orientation: portrait) {
  .is-maximized.has-video .footer-logo-mobile {
    left: 15px;
    height: auto;
    bottom: 40px;
  }
  .is-maximized.has-video .footer-logo {
    bottom: 10px;
  }
  .is-maximized.has-video .footer-logo {
    bottom: 10px;
  }

  .is-maximized.has-video .footer-logo-mobile {
    left: 15px;
    height: auto;
    bottom: 15px;
  }
  .has-video:not(.is-maximized) .options-mobile .menu-toggle {
    left: calc(50% - 2rem);
  }
  #sequence-poll:not(.has-video) .options-mobile .options {
    left: 26%;
  }
  #sequence-poll #sp-footer .footer-btn.menu-toggle {
    left: 50%;
  }
  #sequence-poll:not(.has-video) #back-btn-mobile {
    position: fixed;
    left: 90%;
  }
  .is-maximized.has-video .footer-logo-mobile {
    left: 15px;
    height: auto;
    bottom: 15px;
  }
}
