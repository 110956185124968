.carousel-nav {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.carousel-nav button:hover {
  background: rgb(252, 252, 252);
  background: transparent
    linear-gradient(180deg, #fcfcfc00 0%, #c6c6c6 48%, #fcfcfc00 100%) 0% 0%
    no-repeat padding-box;
  transition: all ease-in-out 400ms;
}
.carousel-nav button:disabled svg {
  display: none;
}
.carousel-nav button:disabled {
  background: transparent
    linear-gradient(180deg, #fcfcfc00 0%, #c6c6c6 48%, #fcfcfc00 100%) 0% 0%
    no-repeat padding-box;
}
.carousel-nav button:disabled:hover {
  background: transparent
    linear-gradient(180deg, #fcfcfc00 0%, #c6c6c6 48%, #fcfcfc00 100%) 0% 0%
    no-repeat padding-box;
}
.carousel-nav button svg {
  color: #fff;
}
.carousel-nav button:not(:disabled):hover svg {
  color: #000;
}

.spacer-y {
  height: 100%;
  width: 1%;
}
.carousel-nav button {
  position: relative;
  height: 100%;
  width: 48px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(252, 252, 252);
  background: transparent
    linear-gradient(180deg, #fcfcfc00 0%, #c6c6c6 48%, #fcfcfc00 100%) 0% 0%
    no-repeat padding-box;
  transition: all ease-in-out 400ms;
  transition-delay: 400ms;
  border: none;
  padding: 0;
}

#navContainer {
  width: 100%;
  height: 20%;
  justify-content: center;
  display: flex;
  /* margin-top: 2%; */
  position: absolute;
  top: -84vh;
}
#navDots {
  height: 100%;
  display: flex;
  justify-content: space-around;
  width: 15%;
  background-color: #ffffff45;
  align-items: center;
  border-radius: 1vh;
}
.navDot {
  cursor: pointer;
  width: 0.5rem;
  height: 0.5rem;

  background-color: #cccccc;
  border-radius: 50%;
}
.navDot.selected {
  background-color: #000000;
}
/* Mobile */
@media only screen and (max-width: 1023px) {
  #navContainer {
    display: none;
  }
  .carousel-nav {
    display: none;
  }
}

.embla {
  position: relative;
  background-color: #f7f7f7;
  padding: 0;
  /* max-width: 670px; */
  margin-left: auto;
  margin-right: auto;
  top: 0;
  width: 100%;
  height: 100%;
  /* z-index:50; */
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  /* margin-left: -10px; */
}

.embla__slide {
  position: relative;
  min-width: 100%;
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.embla__slide__img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
  transition: transform ease-in-out 300ms;
}
.zoomIn {
  transform: translate(-50%, -50%) scale(1.07);
  transition: transform ease-in-out 300ms;
}

.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #1bcacd;
  padding: 0;
}

.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button__svg {
  width: 100%;
  height: 100%;
}

.embla__button--prev {
  left: 27px;
}

.embla__button--next {
  right: 27px;
}
