.Split-One .direct-link {
  width: 100%;
  height: 100%;
}
.Split-Two,
.Split-Four {
  overflow: hidden;
}

.grayhover {
  align-items: center;
  justify-content: center;
  position: absolute;
  transition: background 0.2s;
  background-color: gray;
  opacity: 0.2;
}

img.grid-photo {
  transition: background ease-in-out 500ms, transform ease-in-out 500ms,
    opacity ease-in-out 500ms;
  transform: translateZ(0);
}

/* SPLIT 1 */

.has-video .Split-One.Photo-Grid {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 100%;
}

/* SPLIT 2 */

.has-video .Split-Two.Photo-Grid {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
}

/*SPLIT 3*/
.has-video .Split-Three.Photo-Grid {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
}

.has-video .Split-Three.Photo-Grid.isSafari .card-1,
.Split-Three.Photo-Grid.isSafari .card-1.no-header {
  grid-column-start: 1;
  grid-column-end: 5;
}
.has-video .Split-Three.Photo-Grid.isSafari .card-2,
.Split-Three.Photo-Grid.isSafari .card-2.no-header {
  grid-column-start: 1;
  grid-column-end: 3;
}
.has-video .Split-Three.Photo-Grid.isSafari .card-3,
.Split-Three.Photo-Grid.isSafari .card-3.no-header {
  grid-column-start: 3;
  grid-column-end: 5;
}
.Split-Three img.grid-photo.isSafari.amplifyPic {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* SPLIT 4 */

.has-video .Split-Four.Photo-Grid {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

/* SPLIT 5 */

.Split-Five img.grid-photo.isSafari.amplifyPic {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.has-video .Split-Five.Photo-Grid.isSafari .card-1 {
  grid-column-start: 1;
  grid-column-end: 3;
}
.has-video .Split-Five.Photo-Grid.isSafari .card-2 {
  grid-column-start: 1;
  grid-column-end: 2;
}
.has-video .Split-Five.Photo-Grid.isSafari .card-3 {
  grid-column-start: 2;
  grid-column-end: 3;
}
.has-video .Split-Five.Photo-Grid.isSafari .card-4 {
  grid-column-start: 1;
  grid-column-end: 2;
}
.has-video .Split-Five.Photo-Grid.isSafari .card-5 {
  grid-column-start: 2;
  grid-column-end: 2;
}

.has-video .Split-Five.Photo-Grid.no-header .card-item {
  grid-column-start: auto;
  grid-column-end: auto;
}

.has-video .Split-Six.Photo-Grid {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.has-video .Split-Five.Photo-Grid {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.Split-Five img {
}

/* SPLIT 6 */

.Split-Six img.grid-photo.isSafari,
.Split-Five img.grid-photo.isSafari,
.Split-Four img.grid-photo.isSafari,
.Split-Three img.grid-photo.isSafari,
.Split-Two img.grid-photo.isSafari,
.Split-One img.grid-photo.isSafari {
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;

  background-position: center top;
  border-radius: 10px;
}

.card-item {
  position: relative;
  height: 100%;
  text-align: center;
  overflow: hidden;
}

.card-item.card-1:after {
  animation: shineShort 2s cubic-bezier(0.12, 0.89, 0.98, 0.47) 5s 1,
    shineLong 7s cubic-bezier(0.12, 0.89, 0.98, 0.47) 20s infinite;
}
.card-item.card-2:after {
  animation: shineShort 2s cubic-bezier(0.12, 0.89, 0.98, 0.47) 5.35s 1,
    shineLong 7s cubic-bezier(0.12, 0.89, 0.98, 0.47) 20.35s infinite;
}
.card-item.card-3:after {
  animation: shineShort 2s cubic-bezier(0.12, 0.89, 0.98, 0.47) 5.7s 1,
    shineLong 7s cubic-bezier(0.12, 0.89, 0.98, 0.47) 20.7s infinite;
}
.card-item.card-4:after {
  animation: shineShort 2s cubic-bezier(0.12, 0.89, 0.98, 0.47) 6.05s 1,
    shineLong 7s cubic-bezier(0.12, 0.89, 0.98, 0.47) 21.05s infinite;
}
.card-item.card-5:after {
  animation: shineShort 2s cubic-bezier(0.12, 0.89, 0.98, 0.47) 6.4s 1,
    shineLong 7s cubic-bezier(0.12, 0.89, 0.98, 0.47) 21.4s infinite;
}
.card-item.card-6:after {
  animation: shineShort 2s cubic-bezier(0.12, 0.89, 0.98, 0.47) 6.75s 1,
    shineLong 7s cubic-bezier(0.12, 0.89, 0.98, 0.47) 21.75s infinite;
}

@-webkit-keyframes shineShort {
  to {
    top: 150%;
    left: 150%;
  }
}

@-webkit-keyframes shineLong {
  100% {
    top: 150%;
    left: 150%;
  }
  33% {
    top: 150%;
    left: 150%;
  }
  0% {
    top: -120%;
    left: -120%;
  }
}

.Split-Six img.grid-photo.isSafari.amplifyPic {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.Split-Four img.grid-photo:not(.amplifyPic) {
  object-fit: cover;
}
.z-index-behind {
  z-index: -1;
}

img.grid-photo.amplifyPic {
  transition: top ease-in-out 500ms, left ease-in-out 500ms,
    all ease-in-out 500ms, height ease-in-out 500ms, transform ease-in-out 400ms;
  object-fit: cover;
  border-radius: 1.2vh;
  width: 100%;
}

.Split-Two:not(.isSafari) img.grid-photo.amplifyPic {
  clip-path: none;
  width: 100%;
  height: 100%;
  z-index: 10 !important;
}

.Photo-Grid {
  grid-gap: 2px;
  position: relative;

  border-radius: 18px;
  transform: translateZ(0);
  overflow: hidden;
}

img.grid-photo:hover {
  cursor: pointer;
}

.fadeIn {
  animation: fadeIn ease 400ms;
}

.Split-Four .CardWrapper {
  width: 49.6%;
  height: 49.6%;
  position: absolute;
  overflow: hidden;
}

/* Desktop */

@media only screen and (min-width: 1024px) {
  .Photo-Grid {
    height: 100%;
    transition: all ease-in-out 250ms;
    width: 100%;
  }
  #sequence-poll:not(.has-video) .Photo-Grid {
    grid-gap: 4px;
  }

  .has-header .photo-container .Photo-Grid.isSafari {
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .Photo-Grid.gridAmplify {
    transition: all ease-in-out 250ms;
    height: 100%;
  }

  .has-video .Photo-Grid {
    display: grid;
  }
}
.Split-Six.Photo-Grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.Photo-Grid.no-header {
  height: 100%;
  width: 100%;
}

.has-header .Split-Five.Photo-Grid,
.Split-Five.Photo-Grid.no-header {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 100%;
}
.has-header .Split-Four.Photo-Grid,
.Split-Four.Photo-Grid.no-header {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 100%;
}
.has-header .Split-Three.Photo-Grid,
.Split-Three.Photo-Grid.no-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 100%;
}
.has-header .Split-Two.Photo-Grid,
.Split-Two.Photo-Grid.no-header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100%;
}
.has-header .Split-One.Photo-Grid,
.Split-One.Photo-Grid.no-header {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 100%;
}

.Photo-Grid.no-header img.grid-photo.isSafari {
  display: flex;
  flex-direction: column;
}

.directLinkLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*Widget*/

.is-embedded .Photo-Grid {
  background-color: transparent;
  /*  border: 2px solid #000; */
  border-radius: 18px;
}
.is-embedded .Photo-Grid.isSafari img {
  border-radius: 6px;
}

/*Grid 1*/
.is-embedded .Split-One.Photo-Grid .card-item img {
  border-radius: 18px;
}
/*Grid 2*/
.is-embedded:not(.is-maximized) .Split-Two.Photo-Grid .card-item.card-1 img,
.is-embedded:not(.is-maximized)
  .Split-Two.Photo-Grid
  .card-item.card-1
  .imageWrapper.LoadingIndicator {
  border-radius: 18px 18px 6px 6px;
}
.is-embedded:not(.is-maximized) .Split-Two.Photo-Grid .card-item.card-2 img,
.Split-Two.Photo-Grid .card-item.card-2 .imageWrapper.LoadingIndicator {
  border-radius: 6px 6px 18px 18px;
}
/*Grid 3*/
.is-embedded:not(.is-maximized) .Split-Three.Photo-Grid .card-item.card-1 img,
.Split-Three.Photo-Grid .card-item.card-1 .imageWrapper.LoadingIndicator {
  border-radius: 18px 18px 6px 6px;
}
.is-embedded:not(.is-maximized) .Split-Three.Photo-Grid .card-item.card-3 img,
.Split-Three.Photo-Grid .card-item.card-3 .imageWrapper.LoadingIndicator {
  border-radius: 6px 6px 18px 6px;
}
/*Grid 4*/
.is-embedded:not(.is-maximized) .Split-Four.Photo-Grid .card-item.card-1 img,
.Split-Four.Photo-Grid .card-item.card-1 .imageWrapper.LoadingIndicator {
  border-radius: 18px 6px 6px 6px;
}
.is-embedded:not(.is-maximized) .Split-Four.Photo-Grid .card-item.card-2 img,
.Split-Four.Photo-Grid .card-item.card-2 .imageWrapper.LoadingIndicator {
  border-radius: 6px 18px 6px 6px;
}
.is-embedded:not(.is-maximized) .Split-Four.Photo-Grid .card-item.card-3 img,
.Split-Four.Photo-Grid .card-item.card-3 .imageWrapper.LoadingIndicator {
  border-radius: 6px 6px 6px 18px;
}
.is-embedded:not(.is-maximized) .Split-Four.Photo-Grid .card-item.card-4 img,
.Split-Four.Photo-Grid .card-item.card-4 .imageWrapper.LoadingIndicator {
  border-radius: 6px 6px 18px 6px;
}
/*Grid 5*/
.is-embedded:not(.is-maximized) .Split-Five.Photo-Grid .card-item.card-1 img,
.Split-Five.Photo-Grid .card-item.card-1 .imageWrapper.LoadingIndicator {
  border-radius: 18px 18px 6px 6px;
}
.is-embedded:not(.is-maximized) .Split-Five.Photo-Grid .card-item.card-4 img,
.Split-Five.Photo-Grid .card-item.card-4 .imageWrapper.LoadingIndicator {
  border-radius: 6px 6px 6px 18px;
}
.is-embedded:not(.is-maximized) .Split-Five.Photo-Grid .card-item.card-5 img,
.Split-Five.Photo-Grid .card-item.card-5 .imageWrapper.LoadingIndicator {
  border-radius: 6px 8px 18px 6px;
}
/*Grid 6*/
.is-embedded:not(.is-maximized) .Split-Six.Photo-Grid .card-item.card-1 img,
.is-embedded:not(.is-maximized)
  .Split-Six.Photo-Grid
  .card-item.card-1
  .imageWrapper.LoadingIndicator {
  border-radius: 18px 6px 6px 6px;
}
.is-embedded:not(.is-maximized) .Split-Six.Photo-Grid .card-item.card-2 img,
.is-embedded:not(.is-maximized)
  .Split-Six.Photo-Grid
  .card-item.card-2
  .imageWrapper.LoadingIndicator {
  border-radius: 6px 18px 6px 6px;
}
.is-embedded:not(.is-maximized) .Split-Six.Photo-Grid .card-item.card-5 img,
.is-embedded:not(.is-maximized)
  .Split-Six.Photo-Grid
  .card-item.card-5
  .imageWrapper.LoadingIndicator {
  border-radius: 6px 6px 6px 18px;
}
.is-embedded:not(.is-maximized) .Split-Six.Photo-Grid .card-item.card-6 img,
.is-embedded:not(.is-maximized)
  .Split-Six.Photo-Grid
  .card-item.card-6
  .imageWrapper.LoadingIndicator {
  border-radius: 6px 6px 18px 6px;
}

/*Widget maximized*/
.is-maximized .has-title .Split-One.Photo-Grid {
  border-radius: 0 0 18px 18px;
  padding-bottom: 0;
}

.is-maximized .has-title .Photo-Grid {
  border-radius: 0 0 8px 8px;
}
.is-maximized .has-title .Photo-Grid.no-header,
.is-maximized:not(.has-video) .content:not(.has-title) .Photo-Grid {
  border-radius: 8px 8px;
}
.is-maximized .has-title .Photo-Grid .card-item img,
.is-maximized .has-title .Photo-Grid .card-item .imageWrapper.LoadingIndicator {
  border-radius: 8px;
  padding-bottom: 0;
}

.is-embedded:not(.is-maximized)
  .Photo-Grid
  .card-item
  .imageWrapper.LoadingIndicator {
  display: none;
}
/* Mobile */

@media only screen and (max-width: 1023px) {
  .has-video .Photo-Grid {
    display: grid;
    transition: all ease-in-out 250ms;
  }
  .is-maximized .Photo-Grid {
    transition: all ease-in-out 250ms;
    border: 0;
  }
  .Photo-Grid {
    height: 100%;
    transition: all ease-in-out 250ms;
  }
  .Photo-Grid.gridAmplify {
    transition: all ease-in-out 250ms;
    height: 100%;
  }

  .Split-Five.Photo-Grid.no-header {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
  }

  .has-video .Split-Four.Photo-Grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .has-header .Split-One.Photo-Grid {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 2;
  }
  /*Widget with video is maximized, and featured list grid is opened */
  .is-maximized .has-video .Photo-Grid {
    border-radius: 18px 18px;
  }

  .is-maximized .no-header.Photo-Grid,
  .is-maximized.has-video .Split-Six.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-video .Split-Six.Photo-Grid .card-item.card-2 img,
  .is-maximized.has-video
    .Split-Six.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-video
    .Split-Six.Photo-Grid
    .card-item.card-2
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-video .Split-Five.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-video
    .Split-Five.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-video .Split-Four.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-video
    .Split-Four.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-video .Split-Four.Photo-Grid .card-item.card-2 img,
  .is-maximized.has-video
    .Split-Four.Photo-Grid
    .card-item.card-2
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-video .Split-Three.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-video
    .Split-Three.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-video .Split-Two.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-video
    .Split-Two.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .Split-Three.Photo-Grid .card-item.card-1 .imageWrapper.LoadingIndicator {
    border-radius: 8px;
  }
  .Split-Six img.grid-photo.isSafari,
  .Split-Five img.grid-photo.isSafari,
  .Split-Four img.grid-photo.isSafari,
  .Split-Three img.grid-photo.isSafari,
  .Split-Two img.grid-photo.isSafari,
  .Split-One img.grid-photo.isSafari {
    border-radius: 6px;
  }
  img.grid-photo.amplifyPic {
    border-radius: 6px;
  }
  .Photo-Grid {
    border-radius: 6px;
  }
  .is-embedded .Photo-Grid {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Two.Photo-Grid .card-item.card-1 img,
  .is-embedded:not(.is-maximized)
    .Split-Two.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Two.Photo-Grid .card-item.card-2 img,
  .Split-Two.Photo-Grid .card-item.card-2 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Three.Photo-Grid .card-item.card-1 img,
  .Split-Three.Photo-Grid .card-item.card-1 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Three.Photo-Grid .card-item.card-3 img,
  .Split-Three.Photo-Grid .card-item.card-3 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Four.Photo-Grid .card-item.card-1 img,
  .Split-Four.Photo-Grid .card-item.card-1 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Four.Photo-Grid .card-item.card-2 img,
  .Split-Four.Photo-Grid .card-item.card-2 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Four.Photo-Grid .card-item.card-3 img,
  .Split-Four.Photo-Grid .card-item.card-3 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Four.Photo-Grid .card-item.card-4 img,
  .Split-Four.Photo-Grid .card-item.card-4 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Five.Photo-Grid .card-item.card-1 img,
  .Split-Five.Photo-Grid .card-item.card-1 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Five.Photo-Grid .card-item.card-4 img,
  .Split-Five.Photo-Grid .card-item.card-4 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Five.Photo-Grid .card-item.card-5 img,
  .Split-Five.Photo-Grid .card-item.card-5 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Six.Photo-Grid .card-item.card-1 img,
  .is-embedded:not(.is-maximized)
    .Split-Six.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-maximized .has-title .Photo-Grid {
    border-radius: 6px;
  }
  .is-maximized .has-title .Photo-Grid.no-header,
  .is-maximized:not(.has-video) .content:not(.has-title) .Photo-Grid {
    border-radius: 6px;
  }
  .is-maximized .has-title .Photo-Grid .card-item img,
  .is-maximized
    .has-title
    .Photo-Grid
    .card-item
    .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }

  .is-maximized .no-header.Photo-Grid,
  .is-maximized.has-video .Split-Six.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-video .Split-Six.Photo-Grid .card-item.card-2 img,
  .is-maximized.has-video
    .Split-Six.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-video
    .Split-Six.Photo-Grid
    .card-item.card-2
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-video .Split-Five.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-video
    .Split-Five.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-video .Split-Four.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-video
    .Split-Four.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-video .Split-Four.Photo-Grid .card-item.card-2 img,
  .is-maximized.has-video
    .Split-Four.Photo-Grid
    .card-item.card-2
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-video .Split-Three.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-video
    .Split-Three.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-video .Split-Two.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-video
    .Split-Two.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .Split-Three.Photo-Grid .card-item.card-1 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
}

@media only screen and (max-width: 768px) {
  .has-header .Split-Five.Photo-Grid.isSafari .card-1,
  .Split-Five.Photo-Grid.isSafari.no-header .card-1 {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .has-header .Split-Five.Photo-Grid.isSafari .card-2,
  .Split-Five.Photo-Grid.isSafari.no-header .card-2 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .has-header .Split-Five.Photo-Grid.isSafari .card-3,
  .Split-Five.Photo-Grid.isSafari.no-header .card-3 {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .has-header .Split-Five.Photo-Grid.isSafari .card-4,
  .Split-Five.Photo-Grid.isSafari.no-header .card-4 {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .has-header .Split-Five.Photo-Grid.isSafari .card-5,
  .Split-Five.Photo-Grid.isSafari.no-header .card-5 {
    grid-column-start: 2;
    grid-column-end: 2;
  }

  .has-video .Photo-Grid {
    display: grid;
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) and (orientation: portrait) {
  /* SPLIT 1 */
  .is-embedded .Split-One.Photo-Grid .card-item img {
    border-radius: 6px;
  }
  .has-header.Split-Two.Photo-Grid,
  .Split-Two.Photo-Grid.no-header {
    grid-template-columns: 100%;
    grid-template-rows: repeat(2, 1fr);
  }
  #sequence-poll .Split-Three.Photo-Grid.no-header {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  #sequence-poll .Split-Four.Photo-Grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .has-header .Split-Five.Photo-Grid,
  #sequence-poll .Split-Five.Photo-Grid.no-header {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  #sequence-poll .Split-Six.Photo-Grid,
  #sequence-poll .Split-Six.Photo-Grid.no-header {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1023px) and (orientation: landscape) {
  .Photo-Grid {
    padding-bottom: 0;
  }
  .Split-Four.Photo-Grid .card-item.card-1 .imageWrapper.LoadingIndicator {
    border-radius: 6px 6px 6px 6px;
  }
  .is-maximized.has-video .Photo-Grid {
    border-radius: 6px;
  }
}
@media only screen and (max-width: 1023px) and (orientation: landscape) {
  .is-maximized.has-video
    .Split-Six.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-video .Split-Six.Photo-Grid .card-item.card-1 img {
    border-radius: 6px;
  }
  .is-embedded .Split-One.Photo-Grid .card-item img {
    border-radius: 6px;
  }
}
